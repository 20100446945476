import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuditCase,
  AuditCaseResultDto,
  BaseForm,
  getEnumOptions,
  IAction,
  QuestionnaireAnswerDto,
} from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditCaseDataService } from '../../services/data/audit-case-data.service';

@Component({
  selector: 'app-audit-case-result-item-form',
  templateUrl: './audit-case-result-item-form.component.html',
  styleUrls: ['./audit-case-result-item-form.component.scss'],
})
export class AuditCaseResultItemFormComponent extends BaseForm<AuditCaseResultDto> implements OnInit {
  answersControl = new FormControl(null);
  answersControl2 = new FormControl(null);
  controlClassification = getEnumOptions(AuditCase.ControlClassificationEnum);
  addIssueAction: IAction = {
    id: 1,
    label: 'Add Issue',
    icon: 'pi pi-plus',
    iconPos: 'right',
    command: this.addIssue.bind(this),
    // passEvent:true,
  };
  requestAdditionalInformationAction: IAction = {
    id: 2,
    label: 'Add Request',
    icon: 'pi pi-plus',
    iconPos: 'right',
    command: this.requestAdditionalInformation.bind(this),
    // passEvent:true,
  };
  @Output() onRequestDetails: EventEmitter<any> = new EventEmitter();
  @Output() onAddIssue: EventEmitter<any> = new EventEmitter();
  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditCaseDataService
  ) {
    super(viewModeService, 'AUDIT_CASE_RESULT');
  }
  // plannedStartDate;
  // plannedEndDate;
  ngOnInit(): void {}

  getData() {
    //   return this.viewModeService.viewMode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems
    return [{ key: 'auditorQuestionnaireValue', value: this.answers?.code }];
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      auditor: new FormControl(null, Validators.required),
      auditee: new FormControl(null),
      // plannedStartDate: new FormControl(null, Validators.required),
      // plannedEndDate: new FormControl(null, Validators.required),
      // plannedDuration: new FormControl(null, Validators.required),
      actualStartDate: new FormControl(null),
      actualEndDate: new FormControl(null),
      actualDuration: new FormControl(null),
      actualTimeResources: new FormControl(null),

      cases: new FormControl(null),
      auditProgram: new FormControl(null),
      auditProject: new FormControl(null),

      auditeeQuestionnaire: new FormControl(null),
      auditeeQuestionnaireValue: new FormControl(null),
      auditorQuestionnaire: new FormControl(null),
      auditorQuestionnaireValue: new FormControl(null),

      issues: new FormControl(null),
      auditRequests: new FormControl(null),
    });

    //   this.formGroup.controls.plannedStartDate.valueChanges.subscribe(x => {
    //     if (x && typeof x === 'string') {
    //       this.plannedStartDate = new Date(x);
    //     } else {
    //       this.plannedStartDate = x;
    //     }
    //     this.patchPlannedDuration();
    //   });

    //   this.formGroup.controls.plannedEndDate.valueChanges.subscribe(x => {
    //     if (x && typeof x === 'string') {
    //       this.plannedEndDate = new Date(x);
    //     } else {
    //       this.plannedEndDate = x;
    //     }
    //     this.patchPlannedDuration();
    //   });
  }

  // patchPlannedDuration() {
  //   if (this.plannedStartDate && this.plannedEndDate) {
  //     var difference_ms = this.plannedEndDate.getTime() - this.plannedStartDate.getTime();

  //     var difference_days = Math.floor(difference_ms / (1000 * 60 * 60 * 24));
  //     this.formGroup.controls.plannedDuration.patchValue(difference_days);
  //   }
  // }
  answers = null;
  saveAnswers(event: QuestionnaireAnswerDto) {
    this.answers = event;
    this.onSubmitFormInPlace();
  }
  addIssue() {
    this.onAddIssue.emit(true);
  }
  requestAdditionalInformation() {
    this.onRequestDetails.emit(true);
  }
}
